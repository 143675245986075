/* You can add global styles to this file, and also import other style files */

@mixin o-font {
  font-family: 'Open Sans', sans-serif;
  font-family: 'Roboto', sans-serif;

  font-style: normal;
}

:root {
  @include o-font();
}

.p-component {
  @include o-font();
}

body {
  margin: 0;
  padding: 0;
}

.p-sidebar {
  background: black;

  .p-sidebar-close-icon {
    color: white;
  }

  .p-sidebar-content {
    height: 100%;
  }
}